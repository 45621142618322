<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import treeRoleModule from './treeRoleModule'
import apiTool from '@/command/apiTool'
import { getAction, putAction } from '@/command/netTool'
function unique(arr) {
  return Array.from(new Set(arr))
}

export default {
  name: 'role',
  data() {
    return {
      ...api.command.getState(),
      subordinate: [],
    }
  },
  mounted() {
    getAction('/api/app/list').then((result) => {
      this.subordinate = result.data.map((e) => {
        return {
          ...e,
          name: e.appName,
          value: e.appCode,
        }
      })
    })
    this.getInitData()
  },
  methods: {
    getInitData() {
      api.command.getList
        .call(this, {
          url: '/role/page',
          current: 1,
        })
        .then((e) => {
          let arrData = e.data.records
          this.records = arrData.map((e) => {
            return {
              ...e,
              isAdd: false,
            }
          })
        })
    },
    getHeader() {
      return [
        {
          name: '角色名称',
          type: 'input',
          key: 'roleName',
        },
        {
          name: '角色代码',
          type: 'input',
          key: 'roleCode',
        },
        {
          name: '所属系统',
          type: 'select',
          key: 'sysCode',
          typeData: this.subordinate,
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'roleName',
          title: '角色名称',
          filter: true,
          type: 'lt-100',
          sorter: (a, b) => a.roleName.length - b.roleName.length,
        },
        {
          dataIndex: 'roleCode',
          title: '角色代码',
          type: 'lt-100',
          sorter: (a, b) => a.roleCode.length - b.roleCode.length,
        },
        {
          dataIndex: 'roleDesc',
          title: '描述',
          type: 'lt-100',
          sorter: (a, b) => a.roleDesc.length - b.roleDesc.length,
        },
        {
          dataIndex: 'sysCode',
          title: '所属系统',
          type: 'lt-100',
          sorter: (a, b) => a.sysCode - b.sysCode,
          customRender: (text, records) => {
            return (this.subordinate.find((e) => e.appCode == text) || { appName: '' }).appName
          },
        },
        {
          dataIndex: 'sys',
          title: '系统内置',
          width: '60px',
          type: 'badge',
          onExport: (records) => {
            return ['否', '是'][records]
          },
          filters: [
            {
              text: '否',
              value: '0',
            },
            {
              text: '是',
              value: '1',
            },
          ],
          onFilter: (value, record) => record.status == value,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '否' : '是',
              color: data == 0 ? 'red' : 'green',
            }
          },
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '授权菜单',
                onClick: () => {
                  if (records.roleCode) {
                    this.onRowSelectRole(records)
                  } else {
                    this.$message.warning('此用户无角色代码！')
                    return false
                  }
                },
              },
              //   {
              //     name: '数据权限',
              //     onClick: () => {}
              //   },
              //   {
              //     name: '分配用户',
              //     onClick: () => {}
              //   },
              {
                name: '编辑',
                onClick: () => this.$router.push(`/rights/roleDetail?id=${records.roleCode}`),
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/role/${records.roleCode}`,
                  }),
              },
            ]
          },
        },
      ]
    },
    getParents(tree, select = '', selectArr = []) {
      tree.forEach((e) => {
        if (e.children) {
          let isHas = false
          e.children.forEach((el) => {
            if (select.indexOf(el.id) !== -1) {
              isHas = true
              selectArr.push(el.id)
              if (el.children) {
                this.getParents(el.children, selectArr, [])
              }
            }
          })
          if (isHas) {
            selectArr.push(e.id)
          }
          //   this.getParents(e.children, selectArr, [])
        }
      })
    },
    onTreeRoleData(treeData, cumTreeData) {
      let _this = this
      apiTool.showDrawer({
        title: '分配菜单权限',
        width: '450px',
        view: treeRoleModule,
        viewProps: {
          treeData: treeData,
          cumTreeData: cumTreeData,
        },
        success: ({ data, setHidden }) => {
          let dataList = unique(data)
          if (dataList.length > 0) {
            putAction('/role/menu', {
              menuIds: dataList.toString(),
              roleCode: _this.roleCode,
            }).then((result) => {
              if (result.code === 200) {
                _this.$message.success('操作成功！')
              }
              _this.getInitData()
              setHidden()
            })
          } else {
            _this.$message.warning('请选择菜单权限！')
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    onRowSelectRole(records = { roleCode: null }) {
      this.roleCode = records.roleCode
      let treeData = [],
        cumTreeData = []
      getAction('/api/menu/systemMenus/' + records.sysCode).then((result) => {
        if (result.code == 200) {
          treeData = result.data
          getAction('/api/menu/role/' + records.roleCode).then((result) => {
            if (result.code == 200) {
              cumTreeData = result.data
              this.onTreeRoleData(treeData, cumTreeData)
            } else {
              this.$message.error(result.msg)
            }
          })
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/rights/roleDetail'),
        },
        // {
        //   viewType: 'menu',
        //   name: '更多操作',
        //   isBatch: true,
        //   children: [
        //     {
        //       name: '批量删除',
        //       onClick: data => {
        //
        //       }
        //     }
        //   ]
        // }
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
